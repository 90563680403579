import React from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { TextVariant } from '@naf/text';
import { OrderPaymentStatus } from '@dtp/membership-service-types';
import type { SanityBlock } from '@sanity/client';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import Loader from '../../components/loader';
import { useOrder } from '../../hooks/useOrder';
import S from './Styles';
import { formattedPrice } from '../../lib/formattedPrice';
import { waitingCodes } from '../../hooks/useRunningUpdates';
import BlockContent from '../../components/block-content/BlockContent';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import StepByStepList from '../../components/StepByStepList/StepByStepList';
import useSendEspialPurchaseEventOnce from '../../hooks/Espial/useSendEspialPurchaseEventOnce';
import { SectionBlocks } from '../../components/SectionBlocks';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';

type StepByStep = {
  title: string;
  ingress: string;
  list: {
    title: string;
    body: SanityBlock[];
  }[];
};

interface Props {
  match: { params: { orderId: string } };
}

const LoadingScreen = () => (
  <>
    <GridCol s="12" m="12" l="12" xl="12">
      <S.Header variant={TextVariant.Header1}>Velkommen som medlem</S.Header>
    </GridCol>
    <GridCol s="12" m="12" l="12" xl="12">
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
      <S.AnimatedText variant={TextVariant.Header3}>
        Takk for din betaling. Vi forbereder dine medlemssider.
      </S.AnimatedText>
    </GridCol>
  </>
);

interface OrderSuccessContentProps {
  order: ReturnType<typeof useOrder>;
  windowPath: string;
}

const OrderSuccessContent = ({ order, windowPath }: OrderSuccessContentProps) => {
  const {
    summary: { firstName, customerNumber, orderSummaries, email, grandTotal, isNewCustomer },
    productPaymentOutcome,
  } = order;
  const { paymentReceipt } = productPaymentOutcome || {};

  const content = paymentReceipt?.content || [];
  const stepByStep = (paymentReceipt as any)?.stepByStep as StepByStep;

  return (
    <>
      <GridRow>
        <GridCol s="12" m="12" l="12" xl="12">
          <S.Header variant={TextVariant.Header1}>Velkommen som medlem i NAF, {firstName}!</S.Header>
        </GridCol>
        <GridCol s="12" m="12" l="8" xl="8">
          <S.Wrapper>
            <S.MemberNumber variant={TextVariant.Header3}>Ditt medlemsnummer er: {customerNumber}</S.MemberNumber>
            {isNewCustomer ? (
              <S.StyledP>Vi har sendt deg en e-post til {email}, som bekrefter din bestilling.</S.StyledP>
            ) : null}
            {paymentReceipt?.body ? <BlockContent value={paymentReceipt?.body} /> : null}
            {stepByStep && (
              <StepByStepList
                title={stepByStep.title}
                ingress={stepByStep.ingress}
                list={stepByStep.list}
                variant="secondary"
              />
            )}
          </S.Wrapper>
        </GridCol>
        <GridCol s="12" m="12" l="12" xl="4">
          <S.SummaryWrapper>
            <S.Summary>
              <S.SummaryHeading variant={TextVariant.Header3}>Ditt kjøp</S.SummaryHeading>
              {orderSummaries &&
                orderSummaries.map((orderLine) => (
                  <div key={orderLine.productNumber}>
                    <S.LineWrapper>
                      <S.LineText variant={TextVariant.ArticleTextHeader}>{orderLine.productName}</S.LineText>
                      <S.Price variant={TextVariant.ArticleTextHeader}>
                        {formattedPrice(orderLine.productPrice)} kr
                      </S.Price>
                    </S.LineWrapper>
                    <S.LineText variant={TextVariant.ArticleText}>Antall personer: {orderLine.number}</S.LineText>
                  </div>
                ))}
              <S.LineWrapper $withTopBorder>
                <S.LineText variant={TextVariant.ArticleTextHeader}>Totalt:</S.LineText>
                <S.TotalPrice variant={TextVariant.ArticleTextHeader}>
                  {grandTotal && formattedPrice(grandTotal)} kr
                </S.TotalPrice>
              </S.LineWrapper>
            </S.Summary>
          </S.SummaryWrapper>
        </GridCol>
      </GridRow>
      <GridRow>
        <S.ContentWrapper s="12" m="12" l="12" xl="12">
          <SectionBlocks content={content} />
        </S.ContentWrapper>
      </GridRow>
      <GridRow>
        <GridCol s="12" m="12" l="8" xl="8">
          <FeedbackSurvey title="Savner du noe informasjon på denne siden?" contentUrl={windowPath} />
        </GridCol>
      </GridRow>
    </>
  );
};

interface OrderCancelledContentProps {
  order: ReturnType<typeof useOrder>;
}

const OrderCancelledContent = ({ order }: OrderCancelledContentProps) => (
  <>
    <GridCol s="12" m="12" l="12" xl="12">
      <S.Header variant={TextVariant.Header1}>Du avbrøt betalingen!</S.Header>
    </GridCol>
    <GridCol s="12" m="12" l="6" xl="6">
      <S.Wrapper>
        <S.TextWrapper>
          <BlockContent value={order?.productPaymentOutcome?.paymentCanceled?.body} />
        </S.TextWrapper>
      </S.Wrapper>
    </GridCol>
  </>
);

export const Welcome = ({
  match: {
    params: { orderId },
  },
}: Props) => {
  const order = useOrder(orderId);
  const windowPath = useWindowLocation();

  useSendEspialPurchaseEventOnce(order?.summary?.orderSummaries, true);

  const isLoading =
    !order || (waitingCodes.includes(order.status) && order.paymentOrderStatus === OrderPaymentStatus.PAID);
  const isSuccess =
    order && order.paymentOrderStatus === OrderPaymentStatus.PAID && !waitingCodes.includes(order.status);
  const isCancelled =
    order &&
    (order.paymentOrderStatus === OrderPaymentStatus.CANCELED || order.paymentOrderStatus === OrderPaymentStatus.NONE);

  return (
    <LayoutWithMainContent
      title="Velkommen"
      description="Du har blitt registrert"
      url={windowPath}
      imgUrl={DEFAULT_IMG_URL}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <Grid>
        {isLoading ? <LoadingScreen /> : null}
        {isSuccess ? <OrderSuccessContent order={order} windowPath={windowPath} /> : null}
        {isCancelled ? <OrderCancelledContent order={order} /> : null}
      </Grid>
    </LayoutWithMainContent>
  );
};
